/* // Import Fuse core library */
@import "@fuse/scss/core";

/* // Import app.theme.scss */
@import "app/app.theme";

@import '~angular-tree-component/dist/angular-tree-component.css';

@import "~@ng-select/ng-select/themes/material.theme.css";

@import url('../node_modules/ag-grid-community/dist/styles/ag-grid.css');
@import url('../node_modules/ag-grid-community/dist/styles/ag-theme-fresh.css');
@import url('../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css');


.cke_notifications_area {
    display: none !important;
}

.node-content-wrapper {
    padding: 2px;
}

.node-content-wrapper-active {
    font-weight: 600;
}

.node-content-wrapper-active::after {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
}

.node-content-wrapper,
.tree-children {
    position: relative;
}

.node-content-wrapper::before,
.tree-children::after {
    content: "";
    position: absolute;
}

.node-content-wrapper::before {
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    height: 28px;
    top: -17px;
    width: 20px;
    left: -28px;
}

.tree-node-level-1>tree-node-wrapper>.node-wrapper>.node-content-wrapper::before {
    display: none;
}

.tree-node-leaf>.node-wrapper>.node-content-wrapper::before {
    width: 25px;
}

.tree-children::after {
    border-left: 1px solid lightgrey;
    height: 100%;
    top: -15px;
    left: -15px;
}

tree-node:last-child>.tree-node>.tree-children::after {
    border-left: none;
}

.toggle-children {
    z-index: 1;
}

.tree {
    width: 300px;
}

.happ-form-builder-dialog {
    max-width: 100vw !important;
    height: 100vh;
    .mat-dialog-container {
        padding: 0px;
    }
}

// ---------------- for controll type field ----------------
.happ-field-title {
    // font-size: 24px;
    /* font-weight: 600; */
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 5px;
}

.happ-field-title+div {
    // margin-left: 12px;
    margin-bottom: 8px;
}
.happ-default-title {
    display: flex;
    flex-direction: row;
}

.happ-error-default {
    color: red; 
    font-size: 54px; 
    margin: auto; 
    margin-right: 0px;
}

.main-left-right {
    display: flex;
    flex-direction: row;
    .happ-title {
        display: flex;
        flex: 30%;
        width: 30%;
        justify-content: center;
        align-items: center;
    }
    .happ-content {
        display: flex;
        float: 70%;
        width: 70%;
    }
    .happ-error-icon {
        top: -19px;
        color: red;
        font-size: 50px;
        margin: 0px;
        position: relative;
        left: -10px;
    }
}

.main-right-left {
    display: flex;
    flex-direction: row-reverse;
    .happ-title {
        display: flex;
        flex: 30%;
        width: 30%;
        align-items: center;
    }
    .happ-content {
        display: flex;
        float: 70%;
        width: 70%;
    }
    .happ-error-icon {
        top: -19px;
        color: red;
        font-size: 50px;
        margin: 0px;
        position: relative;
        left: -10px;
    }
}

.happ-form-input {
    border: 1px solid #ddd;
    border-radius: 2px;
    box-sizing: border-box;
    height: 28px;
    padding: 5px;
    margin: 0 0 5px;
    outline: 0;
    width: 100%;
}

.happ-form-section {
    margin-bottom: 16px;
    p {
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

.happ-field-right-align {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px !important;

    p {
        font-weight: 600;
        margin: auto 0px;
        display: block;
        width: 36%;
        // text-align: right;
        

    }
    input {
        border: 1px solid #ddd !important;
        border-radius: 2px !important;
        box-sizing: border-box !important;
        height: 28px !important;
        padding: 5px !important;
        margin: 0 0 5px !important;
        outline: 0 !important;
        width: 40%;
    }
}


.happ-hover-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;

    border: 1px solid currentColor;
    padding: 0 15px;
    line-height: 34px;

    border-color: rgba(0,0,0,.12);

    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);

    margin-right: 8px;
}

.happ-hover-button:hover {
    background: gray;
    color: white;
}

.happ-table {
    table,
    th,
    td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    }

    th {
        font-size: 17px
    }

    table {
    border-collapse: separate;
    border-spacing: 0px;
    border-width: 1px 0 0 1px;
    margin-bottom: 24px;
    width: 100%;
    }

    caption,
    th,
    td {
    font-weight: normal;
    text-align: left;
    }

    th {
    border-width: 0 1px 1px 0;
    font-weight: bold;
    line-height: 24px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 10px
    }

    td {
    border-width: 0 1px 1px 0;
    padding: 10px;
    }

    tr:nth-child(even) {
    background: #EEE;
    }

    
}

.happ-cal-outer-container {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: stretch;
    align-items: stretch;
    overflow: hidden;
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    opacity: 0;
    -webkit-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
    -webkit-border-radius: 8px;
    border-radius: 8px;

    .happ-cal-toolbar {
        flex: none;
        height: 44px;
        overflow: visible;
        position: relative;    
    }

    .happ-cal-content {
        -webkit-box-flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 16px;
        padding-top: 0;
        .happ-cal-heading {
            font-family: 'Google Sans',Roboto,Arial,sans-serif;
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            color: #3c4043;
            max-height: 56px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 28px;
        }

        .happ-cal-text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            padding-bottom: 6px;
            padding-top: 6px;
            -webkit-box-flex: 1;
            box-flex: 1;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Roboto,Arial,sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .2px;
            line-height: 20px;
            color: #3c4043;
        }
    
    }
}
.happ-toolbar {
    padding: 8px 12px;
    background: rgba(200,200,200,0.1);
}


.container {
    overflow: auto !important;
}

.happ-common-list-container {
    border: 1px solid rgba(200,200,200,0.5); 
    min-height: 75px;
    margin-bottom: 8px;
    align-items: center;
    padding: 8px;
}

.happ-common-list-section {
    background: rgb(246, 246, 246);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 12px;
}